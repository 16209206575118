import React, { Suspense } from "react"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"

const ThankYouOnboardingPage = React.lazy(() =>
  import("../components/ThankYouOnboardingPage")
)
const component = Resource[process.env.GATSBY_SITE_VERSION]

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={component.thanksOnboarding.SEO.title}
            keywords={component.thanksOnboarding.SEO.keywords}
            description={component.thanksOnboarding.SEO.description}
          />
          <Layout>
            <ThankYouOnboardingPage
              title={component.thanksOnboarding.title}
              secondTitle={component.thanksOnboarding.text}
            />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
